import { Utils } from '../../common';
import { DateFNS } from '../../common/3rd';
import { PredefinedCheckRuleOptions } from '../../types';
import { getAllRuntimeComponents } from '../../public-component';

export const AgeRange = (options: PredefinedCheckRuleOptions): boolean => {
	let { value, params } = options;
	if (Utils.isEmpty(value) || Utils.isEmpty(params)) {
		// 值为空, 不需要比较, 通过校验
		return true;
	}

	//默认改为了左开右闭
	let min = 0,
		max = 999999,
		includeMax = true,
		includeMin = false,
		minInDay = false,
		maxInDay = false,
		includeBirthCalcDays = false, //计算是否需要把生日当前算作1天
		plus1DayCalcYear = false, // 当天日期 + 1天 计算年龄
		minus1DayCalc = false; // 当天日期 - 1天 计算年龄
		// eslint-disable-next-line
	const defs = params.match(/^(\[|\()?(\d{1,3})?([d|D\y|Y]?),(\d{1,3})?([d|D\y|Y]?)(\]|\)?)([i]?)([P]?)([M]?)$/);
	if (defs == null) {
		throw new Error(`Parameters[${params}] is incorrect.`);
	}
	// 默认开区间，[闭区间
	includeMin = defs[1] === '[' ? true : false;
	if (defs[2]) {
		min = defs[2];
	}
	minInDay = defs[3] === 'd' || defs[3] === 'D';
	if (defs[4]) {
		max = defs[4];
	}
	maxInDay = defs[5] === 'd' || defs[5] === 'D';
	// 右包不写]等价于]，但是)代表开区间
	includeMax = defs[6] === ')' ? false : true;

	includeBirthCalcDays = defs[7] === 'i';

	plus1DayCalcYear = defs[8] === 'P';

	minus1DayCalc = defs[9] === 'M';

	let ageInYear = value;
	let ageInDays = 0;
	if (value.indexOf('/') !== -1) {
		// 是日期
		if (plus1DayCalcYear) {
			ageInYear = DateFNS.diffInYears(DateFNS.addDay(new Date(), 1), value);
		} else if (minus1DayCalc) {
			ageInYear = DateFNS.diffInYears(DateFNS.subDays(new Date(), 1), value);
		} else {
			ageInYear = DateFNS.diffInYears(new Date(), value);
		}
		//是否要包含出生日期作为1天，默认是当前日期为生日时计算天数为0
		if (includeBirthCalcDays) {
			ageInDays = DateFNS.diffInDays(DateFNS.addDay(new Date(), 1), value);
		} else if (minus1DayCalc) {
			ageInDays = DateFNS.diffInDays(DateFNS.subDays(new Date(), 1), value);
		} else {
			ageInDays = DateFNS.diffInDays(new Date(), value);
		}
	}

	if (minInDay) {
		// 最小值按天计算
		if (includeMin) {
			// 包括最小值
			if (ageInDays < min) {
				return false;
			}
		} else if (ageInDays <= min) {
			// 不包括最小值
			return false;
		}
	} else {
		// 最小值按年计算
		if (includeMin) {
			// 包括最小值
			if (ageInYear < min) {
				return false;
			}
		} else if (ageInYear <= min) {
			// 不包括最小值
			return false;
		}
	}

	if (maxInDay) {
		// 最大值按天计算
		if (includeMax) {
			// 包括最大值
			if (ageInDays > max) {
				return false;
			}
		} else if (ageInDays >= max) {
			// 不包括最大值
			return false;
		}
	} else {
		// 最大值按年计算
		if (includeMax) {
			// 包括最大值
			if (ageInYear > max) {
				return false;
			}
		} else if (ageInYear >= max) {
			// 不包括最大值
			return false;
		}
	}

	return true;
};
